import { formatDateDot } from "../../lib/date";
import CONSTANTS from "../../lib/constant";

function DailyGraph({ singleSessionList, isZoomed, ...props }) {
  // const [row, setRow] = useState();

  // console.log(row);

  // const handleZoomLoc = () => {
  //   handleZoom(rowIndex);
  // };

  const getWidth = (length, interval) => {
    return (length * 100) / (interval * 120);
  };

  // useEffect(() => {
  //   setRow(getStagesAndHours(rowIn));
  // }, [props]);

  const getHoursArray = (sessionDetail) => {
    const startTime = new Date(sessionDetail.result.session.start_time);
    const endTime = new Date(sessionDetail.result.session.end_time);
    const hours = [];

    if (isZoomed) {
      let currentHour = startTime.getHours();
      const endHour = (endTime.getHours() + 1) % 24; // end_time의 다음 시간까지 포함
      while (currentHour !== endHour) {
        hours.push(currentHour);
        currentHour = (currentHour + 1) % 24;
      }
    } else {
      const startHour = 12; // 오후 3시
      for (let i = 0; i < 24; i++) {
        hours.push((startHour + i) % 24);
      }
    }

    return hours;
  };

  const formatHour = (hour) => {
    const ampm = hour >= 12 ? "PM" : "AM";
    const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
    return `${formattedHour}${ampm}`;
  };

  const formatMinutes = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    return hours > 0
      ? `${hours}시간 ${remainingMinutes}분`
      : `${remainingMinutes}분`;
  };

  const getStatValues = (sessionDetail) => {
    if (!sessionDetail.result || !sessionDetail.result.stat) return [];
    return CONSTANTS.ASLEE_APP_TABLE_TITLES.map((title) => {
      if (title.title === "sleep latency" || title.title === "time in sleep") {
        return formatMinutes(sessionDetail.result.stat[title.variable]);
      } else if (title.title === "time in light") {
        return `${formatMinutes(
          sessionDetail.result.stat[title.variable]
        )} (${Math.round(sessionDetail.result.stat.light_ratio * 100)}%)`;
      } else if (title.title === "time in deep") {
        return `${formatMinutes(
          sessionDetail.result.stat[title.variable]
        )} (${Math.round(sessionDetail.result.stat.deep_ratio * 100)}%)`;
      } else if (title.title === "time in rem") {
        return `${formatMinutes(
          sessionDetail.result.stat[title.variable]
        )} (${Math.round(sessionDetail.result.stat.rem_ratio * 100)}%)`;
      } else if (title.title === "sleep efficiency") {
        return `${Math.round(
          sessionDetail.result.stat[title.variable] * 100
        )}%`;
      } else return sessionDetail.result.stat[title.variable];
    });
  };

  const getMinutesDifferenceFrom8PM = (startTime, hoursArray) => {
    const start = new Date(startTime);
    const startHour = start.getHours();
    const startMinutes = start.getMinutes();

    if (isZoomed) {
      // isZoomed가 true일 때, hoursArray[0]과 startTime을 비교
      const firstHour = hoursArray[0];
      const diffInHours = (startHour - firstHour + 24) % 24;
      return diffInHours * 60 + startMinutes; // 시간 차이를 분 단위로 변환하고 시작 분 추가
    } else {
      // 기준 시간을 설정: 새벽이면 전날 오후 8시, 아니면 같은 날 오후 8시
      const eightPM = new Date(start);
      if (startHour < 12) {
        // 새벽인 경우
        eightPM.setDate(eightPM.getDate() - 1); // 전날로 설정
      }
      eightPM.setHours(12, 0, 0, 0); // 오후 8시로 설정

      const diffInMilliseconds = start - eightPM;
      return diffInMilliseconds / (1000 * 60); // 분 단위로 변환
    }
  };
  const getDay = (list) => {
    const week = ["일", "월", "화", "수", "목", "금", "토"];
    const dayOfWeek = week[new Date(list.result.session.end_time).getDay()];

    return dayOfWeek;
  };

  return (
    <section>
      {singleSessionList.map((list, index) => {
        const day = getDay(list);
        const statValues = getStatValues(list);
        const hoursArray = getHoursArray(list);

        const realStart = getMinutesDifferenceFrom8PM(
          list.result.session.start_time,
          hoursArray
        );

        // console.log(day);

        return (
          <div className="w-full flex flex-col gap-3 mb-3" key={index}>
            <div
              className={`flex items-center border-2 border-gray-light ${
                day === "토" ? "text-blue-600" : day === "일" ? "text-red" : ""
              }`}
            >
              <div
                className={`  flex flex-col justify-center items-start text-lg text-center font-medium px-3 border-r-2 border-r-gray-light py-2 w-36 flex-shrink-0 flex-grow-0`}
              >
                <span className={`uppercase`}>{day}요일</span>
                {formatDateDot(list.result.session.end_time)}
              </div>

              {hoursArray.map((hour, index) => (
                <p
                  key={`${hour}-${index}`}
                  className="text-left w-full text-xs py-2 pl-1"
                  style={{
                    width: `${
                      isZoomed
                        ? getWidth(120, hoursArray.length)
                        : getWidth(120, 24)
                    }%`,
                  }}
                >
                  <span key={index}>{formatHour(hour)} </span>
                </p>
              ))}
            </div>

            <div className="flex w-full justify-between items-center border-2 border-gray-light h-20">
              <p className="text-lg font-medium px-3 border-r-2 border-r-gray-light py-1 w-36 flex-shrink-0 flex-grow-0 uppercase h-full">
                Sleep stages
              </p>
              <div className="flex w-full h-full items-end relative">
                {!isZoomed &&
                  hoursArray.map((ind) => {
                    return (
                      <div
                        key={`breathStage-${ind}`}
                        className="flex w-full h-full border border-gray-light"
                      ></div>
                    );
                  })}
                <div className="absolute flex w-full h-full items-end">
                  <div
                    style={{
                      width: `${
                        isZoomed
                          ? getWidth(realStart * 2, hoursArray.length)
                          : getWidth(realStart * 2, 24)
                      }%`,
                    }}
                  ></div>
                  {list.result.session.sleep_stages.map((stage, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          height: `${CONSTANTS.SLEEP_STAGES[stage].height}`,
                          width: `${
                            isZoomed
                              ? getWidth([stage].length, hoursArray.length)
                              : getWidth([stage].length, 24)
                          }%`,
                        }}
                      >
                        <div
                          className=" w-full"
                          style={{
                            height: "15%",
                            backgroundColor:
                              CONSTANTS.SLEEP_STAGES[stage].color,
                            opacity: 1,
                          }}
                        />
                        <div
                          className=" w-full"
                          style={{
                            height: "85%",
                            backgroundColor:
                              CONSTANTS.SLEEP_STAGES[stage].color,
                            opacity: 0.5,
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="flex w-full justify-between items-center border-2 border-gray-light h-20">
              <p className="text-lg font-medium px-3 border-r-2 border-r-gray-light py-1 w-36 flex-shrink-0 flex-grow-0 uppercase h-full">
                Breath events
              </p>

              <div className="flex w-full h-full relative">
                {!isZoomed &&
                  hoursArray.map((ind) => {
                    return (
                      <div
                        key={`breathStage-${ind}`}
                        className="flex w-full h-full border border-gray-light"
                      ></div>
                    );
                  })}
                <div className="absolute flex w-full h-full items-end">
                  <div
                    style={{
                      width: `${
                        isZoomed
                          ? getWidth(realStart * 2, hoursArray.length)
                          : getWidth(realStart * 2, 24)
                      }%`,
                    }}
                  ></div>
                  {list.result.session.breath_stages.map((stage, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          backgroundColor: CONSTANTS.APNEA_STAGES[stage].color,
                          height: `100%`,
                          width: `${
                            isZoomed
                              ? getWidth([stage].length, hoursArray.length)
                              : getWidth([stage].length, 24)
                          }%`,
                        }}
                      />
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="flex w-full justify-between items-center border-2 border-gray-light h-32">
              <div className="flex flex-col w-36 h-full flex-grow-0 flex-shrink-0 border-r-2 border-gray-light">
                <p className="text-lg font-medium px-3 py-1 uppercase h-3/5 flex items-center">
                  Summary
                </p>
                <div className="flex justify-start items-start gap-1 h-2/5 w-full">
                  <p className="text-md px-3 py-1 uppercase h-3/5 text-start">
                    {list.result.timezone}
                  </p>
                </div>
              </div>

              <div className="flex w-full h-full overflow-y-hidden overflow-x-scroll ">
                {CONSTANTS.ASLEE_APP_TABLE_TITLES.map((v, ind) => (
                  <div key={ind} className="flex flex-col h-full w-full ">
                    <p
                      className={`row-start-1 text-base px-3 py-1 uppercase flex justify-center items-center text-center ${
                        ind === 0 ? "" : `border-l-2`
                      } border-b-2 border-gray-light w-full h-3/5`}
                    >
                      {v?.title}
                    </p>
                    <p
                      className={`row-start-2 text-[13px] px-3 py-1 flex items-center justify-center ${
                        ind === 0 ? "" : `border-l-2`
                      } border-gray-light h-2/5`}
                    >
                      {statValues[ind]}
                    </p>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex justify-between items-center border-2 border-gray-light">
              <div
                className={`text-lg font-medium px-3 py-1 w-36 flex-shrink-0 flex-grow-0 uppercase h-full`}
              >
                <span className={`uppercase`}>Sleep apnea</span>
              </div>
              <div className="flex w-full h-full">
                <div className="flex flex-col h-full w-full">
                  <p className="row-start-1 text-base px-3 py-2 uppercase flex justify-center items-center text-center border-l-2 border-b-2 border-gray-light w-full h-3/5">
                    Estimated AHI
                  </p>
                  <p className="row-start-2 text-[13px] px-3 py-1 flex items-center justify-center border-l-2 border-gray-light h-2/5">
                    {list.result.session
                      ? list.result.stat.breathing_index
                      : "N/A"}
                  </p>
                </div>
                <div className="flex flex-col h-full w-full">
                  <p className="row-start-1 text-base px-3 py-2 uppercase flex justify-center items-center text-center border-l-2 border-b-2 border-gray-light w-full h-3/5">
                    moderate/severe
                  </p>
                  <p className="row-start-2 text-[13px] px-3 py-1 flex items-center justify-center border-l-2 border-gray-light h-2/5">
                    {list.result.session?.breathing_pattern ===
                      "MODERATELY_UNSTABLE_BREATH" ||
                    list.result.session?.breathing_pattern ===
                      "SEVERELY_UNSTABLE_BREATH"
                      ? "Detected"
                      : "N/A"}
                  </p>
                </div>
              </div>
            </div>
            <p className="w-full block h-[2px] bg-[#eaeaea]"></p>
          </div>
        );
      })}
    </section>
  );

  // return row ? (
  //   <div
  //     className={`flex gap-2 w-full h-fit slee-app-graph`}
  //     id={graphInd}
  //     ref={subComponentRef}
  //   >
  //     <div className="flex flex-col gap-3 h-full w-full">
  //       <div
  //         className={`flex justify-between items-center border-2 border-gray-light ${
  //           row?.day === "saturday"
  //             ? "text-sat-blue"
  //             : row?.day === "sunday"
  //             ? "text-red"
  //             : ""
  //         }`}
  //       >
  //         <div
  //           className={`  flex flex-col justify-center items-start text-lg text-center font-medium px-3 border-r-2 border-r-gray-light py-2 w-36 flex-shrink-0 flex-grow-0`}
  //         >
  //           <span className={`uppercase`}>{row?.day}</span>
  //           <span>{formatDateDot(row?.start_time)}</span>
  //         </div>
  //         {row?.hours &&
  //           row?.hours.map((v, ind) => (
  //             <p
  //               key={`${rowIndex}-hours-${ind}`}
  //               className={`text-left w-full text-xs py-2 pl-1`}
  //               style={{ width: `${getWidth(120, row?.total_hours)}%` }}
  //             >
  //               {v}
  //             </p>
  //           ))}
  //       </div>

  //       <div className="flex w-full justify-between items-center border-2 border-gray-light h-20">
  //         <p className="text-lg font-medium px-3 border-r-2 border-r-gray-light py-1 w-36 flex-shrink-0 flex-grow-0 uppercase h-full">
  //           Sleep stages
  //         </p>
  //         <div className="flex items-end w-full h-full">
  //           {row?.sleep_stages &&
  //             row?.sleep_stages.map((v, ind) =>
  //               v.code === -1 ? (
  //                 <p
  //                   key={`${rowIndex}-sleep-${ind}`}
  //                   className={`text-left w-full h-full text-sm py-1 ${
  //                     ind % 120 === 0 && ind > 0
  //                       ? "border-l-2 border-l-gray-light"
  //                       : ""
  //                   }`}
  //                   style={{
  //                     width: `${getWidth(v.length, row?.total_hours)}%`,
  //                   }}
  //                 ></p>
  //               ) : v.code === -2 ? (
  //                 <p
  //                   key={`${rowIndex}-sleep-border-${ind}`}
  //                   className={`text-left w-[1px] h-full text-sm py-1 border-l-2 border-l-gray-light`}
  //                 ></p>
  //               ) : (
  //                 <div
  //                   key={`${rowIndex}-sleep-stage-${ind}`}
  //                   className="h-full border-none  flex flex-col"
  //                   style={{
  //                     height: CONSTANTS.SLEEP_STAGES[v.code].height,
  //                     width: `${getWidth(v.length, row?.total_hours)}%`,
  //                   }}
  //                 >
  //                   <p
  //                     className="py-1 border-none h-[5px]"
  //                     style={{
  //                       backgroundColor: CONSTANTS.SLEEP_STAGES[v.code].color,
  //                     }}
  //                   ></p>
  //                   <p
  //                     className="h-full py-1 border-none opacity-50"
  //                     style={{
  //                       backgroundColor: CONSTANTS.SLEEP_STAGES[v.code].color,
  //                     }}
  //                   ></p>
  //                 </div>
  //               )
  //             )}
  //         </div>
  //       </div>

  //       <div className="flex w-full justify-between items-center border-2 border-gray-light h-20">
  //         <p className="text-lg font-medium px-3 border-r-2 border-r-gray-light py-1 w-36 flex-shrink-0 flex-grow-0 uppercase h-full">
  //           Breath events
  //         </p>
  //         <div className="flex w-full h-full">
  //           {row?.apnea_stages &&
  //             row?.apnea_stages.map((v, ind) =>
  //               v.code === -1 ? (
  //                 <p
  //                   key={`${rowIndex}-apnea-${ind}`}
  //                   className={`text-left w-full h-full text-sm py-1 ${
  //                     ind % 120 === 0 && ind > 0
  //                       ? "border-l-2 border-l-gray-light"
  //                       : ""
  //                   }`}
  //                   style={{
  //                     width: `${getWidth(v.length, row?.total_hours)}%`,
  //                   }}
  //                 ></p>
  //               ) : v.code === -2 ? (
  //                 <p
  //                   key={`${rowIndex}-apnea-border-${ind}`}
  //                   className={`text-left w-[1px] h-full text-sm py-1 border-l-2 border-l-gray-light`}
  //                 ></p>
  //               ) : (
  //                 <p
  //                   key={`${rowIndex}-apnea-stage-${ind}`}
  //                   className="h-full py-1 border-none"
  //                   style={{
  //                     backgroundColor: CONSTANTS.APNEA_STAGES[v.code].color,
  //                     width: `${getWidth(v.length, row?.total_hours)}%`,
  //                   }}
  //                 ></p>
  //               )
  //             )}
  //         </div>
  //       </div>

  //       <div className="flex w-full justify-between items-center border-2 border-gray-light h-32">
  //         <div className="flex flex-col w-36 h-full flex-grow-0 flex-shrink-0">
  //           <p className="text-lg font-medium px-3 py-1 uppercase h-3/5 flex items-center">
  //             Summary
  //           </p>
  //           <div className="flex justify-start items-start gap-1 h-2/5 w-full">
  //             <p className="text-md px-3 py-1 uppercase h-3/5 text-start">
  //               {row?.timezone}
  //             </p>
  //             {/* <div className='flex items-center px-3 gap-1 h-2/5'> */}
  //             {/* {
  //                               icons.map((a) => (
  //                                   <img src={a} className='w-5 h-5 border border-gray-light rounded-full p-[1px]' />
  //                               ))
  //                           } */}
  //           </div>
  //         </div>

  //         <div className="flex w-full h-full">
  //           {CONSTANTS.ASLEE_APP_TABLE_TITLES.map((v, ind) => (
  //             <div
  //               className="flex flex-col h-full w-full"
  //               key={`${rowIndex}-title-${ind}`}
  //             >
  //               <p className="row-start-1 text-base px-3 py-1 uppercase flex justify-center items-center text-center border-l-2 border-b-2 border-gray-light w-full h-3/5">
  //                 {v.title}
  //               </p>
  //               <p className="row-start-2 text-[13px] px-3 py-1 flex items-center justify-center border-l-2 border-gray-light h-2/5">
  //                 {row[v.variable]}
  //               </p>
  //             </div>
  //           ))}
  //         </div>
  //       </div>

  //       <div className="flex justify-between items-center border-2 border-gray-light">
  //         <div
  //           className={`h-full flex flex-col justify-center items-start text-lg text-center font-medium px-3 border-r-2 border-r-gray-light py-2 w-36 flex-shrink-0 flex-grow-0`}
  //         >
  //           <span className={`uppercase`}>Sleep note</span>
  //         </div>
  //         <p className={`text-left w-full text-sm py-2 px-2`}>
  //           {row?.sleep_note && row?.sleep_note != "" ? row?.sleep_note : "N/A"}
  //         </p>
  //       </div>
  //       <div className="flex justify-between items-center border-2 border-gray-light">
  //         <div
  //           className={`text-lg font-medium px-3 py-1 w-36 flex-shrink-0 flex-grow-0 uppercase h-full`}
  //         >
  //           <span className={`uppercase`}>Sleep apnea</span>
  //         </div>
  //         <div className="flex w-full h-full">
  //           <div className="flex flex-col h-full w-full">
  //             <p className="row-start-1 text-base px-3 py-2 uppercase flex justify-center items-center text-center border-l-2 border-b-2 border-gray-light w-full h-3/5">
  //               Estimated AHI
  //             </p>
  //             <p className="row-start-2 text-[13px] px-3 py-1 flex items-center justify-center border-l-2 border-gray-light h-2/5">
  //               {row.session ? row.session.estimated_ahi : "N/A"}
  //             </p>
  //           </div>
  //           <div className="flex flex-col h-full w-full">
  //             <p className="row-start-1 text-base px-3 py-2 uppercase flex justify-center items-center text-center border-l-2 border-b-2 border-gray-light w-full h-3/5">
  //               moderate/severe
  //             </p>
  //             <p className="row-start-2 text-[13px] px-3 py-1 flex items-center justify-center border-l-2 border-gray-light h-2/5">
  //               {row.session?.breathing_pattern ===
  //                 "MODERATELY_UNSTABLE_BREATH" ||
  //               row.session?.breathing_pattern === "SEVERELY_UNSTABLE_BREATH"
  //                 ? "Detected"
  //                 : "N/A"}
  //             </p>
  //           </div>
  //         </div>
  //       </div>

  //       <GrayLine />
  //     </div>

  // <div className="flex flex-col pt-3 gap-12">
  //   <button onClick={handleZoomLoc} disabled={row?.disabled}>
  //     <img
  //       src={row?.zoom ? ZoomGrayIcon : ZoomBlueIcon}
  //       className="w-7 h-7"
  //     />
  //   </button>
  // </div>
  //   </div>
  // ) : (
  //   ""
  // );
}

export default DailyGraph;
