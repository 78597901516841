import { useEffect, useState } from "react";
import { Calendar } from "react-date-range";
import CalendarLightIcon from "../../../assets/calendar-icon-dark.svg";
import axios from "axios";
import BaseModal from "../BaseModal";
import ModalTitle from "../ModalTitle";
import ButtonDefault from "../../ButtonDefault";
import { formatDateDash } from "../../../lib/date";
import { ko } from "date-fns/locale";
import "react-date-range/dist/styles.css"; // 기본 스타일
import "react-date-range/dist/theme/default.css";
import "./customStyle.css";

// import { ko } from "date-fns";

function RegisterPatientModal({
  setList,
  handleCloseModal,
  filteredList,
  setFilteredList,
  survey,
  setSurvey,
}) {
  const accessToken = localStorage.getItem("access_token");

  const [formData, setFormData] = useState({
    name: "",
    birth_date: "",
    gender: "",
    tonsil_size: "",
    stop_bang_responses: [],
  });
  // const [isValidDate, setIsValidDate] = useState(true);
  const [isNotEmpty, setIsNotEmpty] = useState({
    name: undefined,
    birth_date: undefined,
    gender: undefined,
    tonsil_size: undefined,
  });
  const [surveyData, setSurveyData] = useState([]);

  const [showCalendar, setShowCalendar] = useState(false);

  const handleChange = (e) => {
    e.preventDefault();

    const { name, value } = e.target;

    setIsNotEmpty({
      ...isNotEmpty,
      [name]: value ? true : false,
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSelectDate = (date) => {
    const formattedDate = formatDateDash(date);
    setFormData({
      ...formData,
      birth_date: formattedDate,
    });
    setShowCalendar(false);
  };

  const validateForm = () => {
    const { name, birth_date, gender, tonsil_size } = formData;
    const isNameValid = name?.trim() !== "";
    const isBirthValid = birth_date !== "";
    const isGenderValid = gender?.trim() !== "";
    const isNeckCircumValid = tonsil_size?.trim() !== "";

    return isNameValid && isBirthValid && isGenderValid && isNeckCircumValid;
  };

  const validateSurvey = () => {
    return surveyData.every(
      (item) => item.check === true || item.check === false
    );
  };

  const addPatientList = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_APNOTRACK_URL}/v1/hub/doctors/me/patients`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const updatedResponse = await axios.get(
        `${process.env.REACT_APP_APNOTRACK_URL}/v1/hub/doctors/me/patients`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setList(updatedResponse.data.result);
      handleCloseModal();
      setFilteredList(updatedResponse.data.result);

      console.log("Data updated successfully:", response.data);
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  useEffect(() => {
    fetch("data/survey.json")
      .then((res) => res.json())
      .then((data) => setSurveyData(data));
  }, []);

  const handleSurveyClick = (index, answer) => {
    setSurveyData((prevData) =>
      prevData.map((item, idx) =>
        idx === index ? { ...item, check: answer } : item
      )
    );

    // stop_bang_responses 업데이트
    setFormData((prevFormData) => {
      const updatedResponses = [...prevFormData.stop_bang_responses];
      updatedResponses[index] = answer; // 해당 인덱스에 값을 넣음
      return {
        ...prevFormData,
        stop_bang_responses: updatedResponses,
      };
    });
  };

  const renderTextWithNewLines = (text) => {
    return text.split("\n").map((str, index) => (
      <span key={index}>
        {str}
        <br />
      </span>
    ));
  };

  const handleOverlayClick = () => {
    setShowCalendar(false);
  };

  return (
    <BaseModal handleCloseModal={handleCloseModal}>
      <div
        className={`flex justify-between items-baseline gap-4 px-20 pt-8 pb-6`}
      >
        <ModalTitle title="환자 등록" note="" />
        <span
          className="flex justify-center items-center font-bold text-[#1C265F] cursor-pointer"
          onClick={handleCloseModal}
        >
          CLOSE
        </span>
      </div>
      {survey === false ? (
        <section className="flex flex-col gap-8 px-20 items-center">
          <div className="w-full">
            <div className="mb-4">
              <input
                type="text"
                name="name"
                placeholder="이름"
                autoComplete="off"
                value={formData.name}
                onChange={handleChange}
                className="w-full px-4 py-3 border-2 border-gray-light"
              />
            </div>
            <div className="mb-4">
              <div
                className="flex items-center border-2 border-gray-light px-4 py-3 w-full cursor-pointer "
                onClick={() => setShowCalendar(!showCalendar)}
              >
                <input
                  type="text"
                  value={
                    formData.birth_date
                      ? formatDateDash(formData.birth_date)
                      : ""
                  }
                  className={`w-full cursor-pointer`}
                  placeholder="생년월일"
                  readOnly
                />
                <img src={CalendarLightIcon} alt="none" className="w-[5%]" />
              </div>
              {showCalendar && (
                <div>
                  <div
                    className="fixed inset-0 bg-gray-500 bg-opacity-50 z-10"
                    onClick={handleOverlayClick}
                  ></div>

                  <div className="absolute top-1/3 left-1/2 transform -translate-x-1/2 mt-2 z-40 w-[40%]">
                    <div className="custom-date-range">
                      <Calendar
                        direction="horizontal"
                        onChange={handleSelectDate}
                        date={
                          formData.birth_date
                            ? new Date(formData.birth_date)
                            : new Date()
                        }
                        locale={ko}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="mb-4 w-full px-4 py-3 border-2 border-gray-light">
              <label>
                <select
                  onChange={handleChange}
                  defaultValue={formData.gender || "default"}
                  className="w-full"
                  name="gender"
                  id="gender"
                >
                  <option value="default" disabled>
                    성별
                  </option>
                  <option value="MALE">남자</option>
                  <option value="FEMALE">여자</option>
                </select>
              </label>
            </div>
            <div className="mb-4 w-full px-4 py-3 border-2 border-gray-light">
              <label>
                <select
                  onChange={handleChange}
                  defaultValue={formData.tonsil_size || "default"}
                  className="w-full"
                  name="tonsil_size"
                >
                  <option className="text-gray-light" value="default" disabled>
                    편도 크기 검사
                  </option>
                  <option value={1}>Grade 1</option>
                  <option value={2}>Grade 2</option>
                  <option value={3}>Grade 3</option>
                  <option value={4}>Grade 4</option>
                </select>
              </label>
            </div>

            <section className="flex justify-center gap-4 px-20 pt-8 pb-6 text-center">
              <ButtonDefault
                value="NEXT"
                disabled={!validateForm()}
                handleClick={() => setSurvey(true)}
              />
            </section>
          </div>
        </section>
      ) : (
        <div className="px-20">
          <p className="mb-8 text-[#4c5463] text-[22px] font-extrabold font-['Pretendard'] uppercase">
            개정 버전 STOP-BANG SURVEY
          </p>
          <section className=" h-[600px] pr-[12px] overflow-scroll">
            {surveyData.map(({ list, guideText, check }, index) => (
              <div key={index} className="flex flex-col gap-4 mb-12">
                <div className="flex justify-between">
                  <div className="text-[#1c265f] text-[20px] font-bold">
                    {index + 1}. {renderTextWithNewLines(list)}
                  </div>
                  <div className="flex gap-4">
                    <button
                      className={`w-[80px] h-[40px] px-4 py-2 rounded-[33px] ${
                        check === true
                          ? "bg-[#1c265f] text-white"
                          : "bg-[#eaeaea] text-[#1c265f]"
                      }`}
                      onClick={() => handleSurveyClick(index, true)}
                    >
                      예
                    </button>
                    <button
                      className={`w-[80px] h-[40px] px-4 py-2 rounded-[33px] ${
                        check === false
                          ? "bg-[#1c265f] text-white"
                          : "bg-[#eaeaea] text-[#1c265f]"
                      }`}
                      onClick={() => handleSurveyClick(index, false)}
                    >
                      아니오
                    </button>
                  </div>
                </div>
                <div>{renderTextWithNewLines(guideText)}</div>
              </div>
            ))}
          </section>
          <section className="flex justify-center gap-4 px-20 pt-8 pb-6 text-center">
            <ButtonDefault
              value="RESISTER"
              disabled={!validateSurvey()}
              handleClick={addPatientList}
            />
          </section>
        </div>
      )}
    </BaseModal>
  );
}

export default RegisterPatientModal;
