import BaseModal from "../BaseModal";
import { Calendar } from "react-date-range";

import ModalTitle from "../ModalTitle";
import CalendarLightIcon from "../../../assets/calendar-icon-dark.svg";
import ButtonDefault from "../../ButtonDefault";
import { useState } from "react";
import axios from "axios";
import { formatDateDash } from "../../../lib/date";
import { format } from "date-fns";
import { ko } from "date-fns/locale";

function IssueCodeModal({
  handleCloseModal,
  existCode,
  id,
  setExistCode,
  setDetailData,
  detailData,
}) {
  const accessToken = localStorage.getItem("access_token");

  const [formData, setFormData] = useState({
    patient_id: id,
    start_date: formatDateDash(new Date()),
    end_date: formatDateDash(
      new Date(formatDateDash(new Date())).setDate(new Date().getDate() + 7)
    ),
  });

  const [showCalendar, setShowCalendar] = useState(false);

  // const handleDateChange = (e) => {
  //   e.preventDefault();

  //   const { name, value } = e.target;

  //   // start_date 변경 시 end_date도 업데이트
  //   if (name === "start_date") {
  //     const startDate = new Date(value);
  //     const endDate = new Date(startDate);
  //     endDate.setDate(startDate.getDate() + duration); // 현재 선택된 기간으로 end_date 설정

  //     setFormData({
  //       ...formData,
  //       start_date: value,
  //       end_date: formatDateDash(endDate), // YYYY-MM-DD 형식으로 end_date 설정
  //     });
  //   } else {
  //     setFormData({
  //       ...formData,
  //       [name]: value,
  //     });
  //   }
  // };

  const handleDurationChange = (e) => {
    e.preventDefault();

    const selectedDuration = parseInt(e.target.value);
    const startDate = new Date(formData.start_date);
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + selectedDuration); // 선택한 기간만큼 end_date 설정

    setFormData({
      ...formData,
      end_date: formatDateDash(endDate), // YYYY-MM-DD 형식으로 변환
    });
  };

  const isValidDate = () => {
    return formData.start_date < formData.end_date;
  };

  const handleIssueCode = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_APNOTRACK_URL}/v1/hub/doctors/me/patients/${id}/prescriptions`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      handleCloseModal();
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectDate = (date) => {
    const formattedDate = formatDateDash(date);
    setFormData({
      ...formData,
      start_date: formattedDate,
    });
    setShowCalendar(false);
  };

  return (
    <BaseModal handleCloseModal={handleCloseModal}>
      <div className="flex justify-between items-baseline gap-4 px-20 pt-8 pb-6">
        <ModalTitle
          title="처방 기간 설정"
          note={existCode ? null : "처방기간을 설정하세요."}
        />
        <span
          className="flex justify-center items-center font-bold text-[#1C265F] cursor-pointer"
          onClick={handleCloseModal}
        >
          CLOSE
        </span>
      </div>

      <section className="flex flex-col gap-12 px-20 pt-8 pb-6 text-center items-center">
        <div className="flex flex-col items-center w-full gap-24">
          <div className="flex justify-around w-full gap-[10%]">
            <div className="flex w-1/2 flex-col items-start gap-[20px]">
              <span className="text-black">시작 날짜</span>
              {/* <input
                className="w-full h-[30px] border-b"
                type="date"
                name="start_date"
                value={formData.start_date}
                onChange={handleDateChange}
              /> */}
              <div
                className="flex items-center w-full cursor-pointer border-b"
                onClick={() => setShowCalendar(!showCalendar)}
              >
                <input
                  type="text"
                  value={
                    formData.start_date
                      ? `${format(formData.start_date, "yy.MM.dd")} ${
                          formData.start_date ===
                          format(new Date(), "yyyy-MM-dd")
                            ? "(오늘)"
                            : ""
                        }`
                      : ""
                  }
                  className="w-full h-[30px] cursor-pointer "
                  placeholder={`${format(new Date(), "yy.MM.dd")} (오늘)`}
                  readOnly
                />
                <img src={CalendarLightIcon} alt="none" className="w-[5%]" />
              </div>
            </div>
            {showCalendar && (
              <div>
                <div
                  className="fixed inset-0 bg-gray-500 bg-opacity-50 z-10"
                  onClick={() => {
                    setShowCalendar(false);
                  }}
                ></div>
                <div className="absolute top-1/3 left-1/2 transform -translate-x-1/2 mt-2 z-40 w-[40%]">
                  <div className="custom-date-range">
                    <Calendar
                      direction="horizontal"
                      onChange={handleSelectDate}
                      date={
                        formData.start_date
                          ? new Date(formData.start_date)
                          : new Date()
                      }
                      locale={ko}
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="flex w-1/2 flex-col items-start gap-[20px]">
              <span className="text-black">처방 기간</span>
              <select
                onChange={handleDurationChange}
                className="w-full h-[30px] border-b"
              >
                <option value="7">7일</option>
                <option value="30">30일</option>
              </select>
            </div>
          </div>
          <ButtonDefault
            value="저장"
            disabled={!isValidDate()}
            handleClick={handleIssueCode}
          />
        </div>
      </section>
    </BaseModal>
  );
}

export default IssueCodeModal;
