import InputDefault from "../InputDefault";

function SignUp({
  handleInput,
  name,
  email,
  hospital,
  LicenseNumber,
  signUpRules,
}) {
  return (
    <>
      <InputDefault
        id={"full_name"}
        value={name}
        placeholder="Full Name..."
        handleChange={handleInput}
        success={signUpRules.full_name}
      />
      <InputDefault
        id="email"
        value={email}
        placeholder="Email(ID)"
        handleChange={handleInput}
        success={signUpRules.email}
      />
      <InputDefault
        id={"hospital_name"}
        value={hospital}
        placeholder="Hospital name"
        handleChange={handleInput}
        type="string"
        success={signUpRules.hospital_name}
      />
      <InputDefault
        id={"license_number"}
        value={LicenseNumber}
        placeholder="Physician's License Number"
        handleChange={handleInput}
        type="number"
        success={signUpRules.license_number}
      />
    </>
  );
}

export default SignUp;
