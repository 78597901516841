import { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import InputDefault from "../../../components/InputDefault";
import ButtonDefault from "../../../components/ButtonDefault";
import useValidatedState from "../../../hooks/useValidatedState";
import FrontPage from "../../../components/FrontPage";
import axios from "axios";
function AdminLogin() {
  const navigate = useNavigate();
  const location = useLocation();
  // const [startDate, setStartDate] = useState(getDateBeforeNDays(14));
  //   const [endDate, setEndDate] = useState(getDateBeforeNDays(0));

  const [id, setId, idValidationMsg] = useValidatedState({
    required: true,
    fieldName: "email",
    type: "email",
  });
  const [password, setPassword, passwordValidationMsg] = useValidatedState({
    required: true,
    fieldName: "password",
    type: "password",
  });

  // const [startDate, setStartDate, startDateValidationMsg] = useValidatedState({
  //   required: false,
  //   fieldName: "start date",
  //   type: "date",
  //   defaultVal: getDateArray(getDateBeforeNDays(14)),
  // });
  // const [endDate, setEndDate, endDateValidationMsg] = useValidatedState({
  //   required: false,
  //   fieldName: "end date",
  //   type: "date",
  //   defaultVal: getDateArray(getDateBeforeNDays(0)),
  // });

  const [reset, setReset] = useState(false);
  const [complete, setComplete] = useState(false);
  const [signUp, setSignUp] = useState(false);
  const [registered, setRegistered] = useState(false);
  const [changePswd, setChangePswd] = useState("");

  const isAllInputValid = () => {
    let allFields = reset
      ? [passwordValidationMsg]
      : location.pathname === "/signup"
      ? [
          signUpRules.full_name ? undefined : "",
          signUpRules.email ? undefined : "",
          signUpRules.hospital_name ? undefined : "",
          signUpRules.license_number ? undefined : "",
        ]
      : [idValidationMsg, passwordValidationMsg];
    // console.log(allFields);
    for (let i of allFields) {
      if (i !== undefined) {
        return false;
      }
    }

    return true;
  };

  const handleSubmit = async (e) => {
    if (e.target.value === "SAVE") {
      setComplete(true);
    } else if (e.target.value === "LOG IN") {
      // PUT Method로 정보 수정하기.
      setComplete(false);
      setReset(false);
      setRegistered(false);
      setPassword("");
    } else if (e.target.value === "CONTINUE") {
      console.log(id, password);
      try {
        await axios
          .post(
            "https://virtserver.swaggerhub.com/asleepmockapi/Apnotrack/1.0.0/api/v2/login",
            { email: id, password }
          )
          .then((data) => {
            console.log(data.data.refresh_token);
            if (data.data.access_token) {
              localStorage.setItem("access_token", data.data.access_token);
              sessionStorage.setItem("refresh_token", data.data.refresh_token);
              // navigate("/admin/doctor-list");
            } else {
              alert("다시 시도해주세요.");
            }
          });
        // .then(navigate("/admin/doctor-list"));
      } catch (error) {
        console.error(error);
      }

      //POST url : https://virtserver.swaggerhub.com/asleepmockapi/Apnotrack/1.0.0/api/v2/doctor/signup
    }
  };

  // useEffect(() => {
  //   let urlParams = parseURL(location.search);
  //   console.log("Parsed url", urlParams);
  //   if (urlParams) {
  //     setStartDate(getDateArray(urlParams?.start_date));
  //     setEndDate(getDateArray(urlParams?.end_date));
  //     setId(urlParams?.uuid);
  //   }
  // }, []);

  const resetPswd = () => {
    setReset(true);
    setId("");
    setPassword("");
  };
  const handleId = (e) => {
    setId(e.target.value);
  };

  const handlePswd = (e) => {
    setPassword(e.target.value);
  };

  const handleChangePswd = (e) => {
    setChangePswd(e.target.value);
  };

  const [inputValues, setInputValues] = useState({
    full_name: "",
    email: "",
    hospital_name: "",
    license_number: "",
  });

  const mailformat =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const { full_name, email, hospital_name, license_number } = inputValues;

  const signUpRules = {
    full_name: full_name.length >= 2,
    email: email.match(mailformat),
    hospital_name: hospital_name.length > 2,
    license_number: license_number.length > 2,
  };

  const handleInput = (e) => {
    const { id, value } = e.target;

    setInputValues({ ...inputValues, [id]: value });
  };

  useEffect(() => {
    if (location.pathname === "/signup") {
      setComplete(false);
      setReset(false);
      setRegistered(false);
    }
  }, [location.pathname]);

  return (
    <FrontPage
      title={"Login"}
      note={"Log in to your account."}
      btnDisabled={!isAllInputValid()}
      handleClick={handleSubmit}
      reset={reset}
      complete={complete}
      registered={registered}
      correct={password === changePswd}
      pathName={location.pathname}
    >
      <div className="flex flex-col gap-4 py-6 w-full">
        <InputDefault
          id={id}
          value={id || ""}
          placeholder="Email"
          handleChange={handleId}
          reset={reset}
          errorMsg={idValidationMsg}
          success={!idValidationMsg}
        />
        <InputDefault
          id={password || ""}
          value={password || ""}
          placeholder="password"
          handleChange={handlePswd}
          type="password"
          // reset={reset}
          errorMsg={passwordValidationMsg}
          success={!passwordValidationMsg}
        />
      </div>
    </FrontPage>
  );
}

export default AdminLogin;
