import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Apnotrack from "../../assets/apnotrackhub.svg";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import Icon from "../../components/Icon";
import ButtonDefault from "../../components/ButtonDefault";
import RegisterPatientModal from "../../components/modals/RegisterPatientModal";

function PatientList() {
  const [list, setList] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredList, setFilteredList] = useState([]);
  const [sortValue, setSortValue] = useState("basic");
  const [showModal, setShowModal] = useState(false);
  const [survey, setSurvey] = useState(false);

  const navigate = useNavigate();

  const accessToken = localStorage.getItem("access_token");

  useEffect(() => {
    axios(`${process.env.REACT_APP_APNOTRACK_URL}/v1/hub/doctors/me/patients`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((data) => {
        setList(data.data.result);
        setFilteredList(data.data.result);
      })
      .catch((error) => {
        console.log(error.response.statusText);
        if (error.response.statusText === "Unauthorized") {
          navigate("/");
        }
      });
  }, [accessToken]);

  useEffect(() => {
    setFilteredList(sortList(list, sortValue));
  }, [list, sortValue]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    const filtered = list?.filter((item) =>
      item.name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredList(sortList(filtered, sortValue));
  };

  const sortList = (listToSort, sortValue) => {
    if (sortValue === "basic") {
      return list?.filter((item) => listToSort.includes(item)); // 기본 리스트 순서 유지
    }

    return [...listToSort].sort((a, b) => {
      if (sortValue === "name") {
        return a.name.localeCompare(b.name);
      } else if (sortValue === "patientsCode") {
        return a.patient_code - b.patient_code;
      } else if (sortValue === "is_registered") {
        const stateOrder = { true: 1, false: 2 };

        return stateOrder[a.is_registered] - stateOrder[b.is_registered];
      }
      return 0; // 기본 순서 유지
    });
  };

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}.${month}.${day}`;
  };

  // MEMO : 정렬기능 필요할 때 주석해제
  const Sorting = (e) => {
    const sortValue = e.target.value;
    setSortValue(sortValue);
    setFilteredList(sortList(filteredList, sortValue));
  };

  const openModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSurvey(false);
  };

  return (
    <div className="h-[100vh]  m-auto col-span-full  flex flex-col w-full gap-6 p-8 bg-cream ">
      {showModal && (
        <RegisterPatientModal
          handleCloseModal={handleCloseModal}
          filteredList={filteredList}
          setFilteredList={setFilteredList}
          setList={setList}
          survey={survey}
          setSurvey={setSurvey}
        />
      )}
      <section className="flex justify-between items-end">
        <img src={Apnotrack} className="w-[150px]" alt="apnotrack" />
        <div className="relative">
          <input
            className="w-[250px] h-[40px] p-[12px] shadow-default "
            placeholder="검색"
            value={search}
            onChange={handleSearch}
          />
          <div className="absolute top-[50%] right-3 mt-[-10px] cursor-pointer ">
            <Icon icon={faMagnifyingGlass} size="xl" />
          </div>
        </div>
      </section>
      <div className=" h-[84vh] overflow-y-scroll w-full bg-white pl-8 pr-8 pb-10 scroll-m-2 shadow-default flex flex-col gap-3">
        <h1 className="font-bold text-4xl mb-[30px] pt-10 text-black bg-white">
          환자 목록
        </h1>

        <div className="flex justify-between items-end">
          <label htmlFor="sort">
            정렬 :
            <select name="sort" id="sort" onChange={Sorting}>
              <option value="basic">기본순</option>
              <option value="name">이름순</option>
            </select>
          </label>
          <ButtonDefault
            value="+ 환자 등록"
            size="small"
            handleClick={openModal}
          />
        </div>
        <table className="text-center">
          <thead>
            <tr>
              <th>이름</th>
              <th>생년월일</th>
              <th>성별</th>
              <th>환자코드</th>
              <th>환자코드등록</th>
            </tr>
          </thead>
          <tbody>
            {list.length > 0 ? (
              filteredList?.map(
                ({
                  birth_date,
                  gender,
                  patient_code,
                  id,
                  name,
                  start_date,
                  is_registered,
                  ast_id,
                  end_date,
                  stop_bang_responses,
                  tonsil_size,
                }) => {
                  const birthDay = new Date(birth_date);
                  const year = String(birthDay.getFullYear()).slice(-2);
                  const month = String(birthDay.getMonth() + 1).padStart(
                    2,
                    "0"
                  );
                  const day = String(birthDay.getDate()).padStart(2, "0");

                  let Gender;
                  if (gender === "MALE") {
                    Gender = "남";
                  } else if (gender === "FEMALE") {
                    Gender = "여";
                  } else {
                    Gender = "기타";
                  }

                  // const isPast = isDatePast(Period);

                  const NavigateDetail = () => {
                    navigate(`/sleep-summary?code=${patient_code}`, {
                      state: {
                        birth_date,
                        gender,
                        patient_code,
                        id,
                        name,
                        start_date,
                        end_date,
                        is_registered,
                        ast_id,
                        stop_bang_responses,
                        tonsil_size,
                      },
                    });
                  };

                  return (
                    <tr key={`${id}-${patient_code}`}>
                      <td onClick={NavigateDetail} className="cursor-pointer">
                        {name}
                      </td>
                      <td>
                        {year}.{month}.{day}
                      </td>
                      <td>{Gender}</td>
                      <td>{patient_code}</td>

                      <td className="items-center ">
                        {/* 백엔드 api 업데이트되면 코드 등록 상태값에 따라서 변경할 부분 */}
                        <div
                          className={`${
                            is_registered === true
                              ? "text-[#2CBC77] bg-[#F1FFF0]"
                              : "text-[#929292] bg-[#F6F6F6]"
                          } rounded-[4px] w-[79px] m-auto`}
                        >
                          {is_registered ? "완료" : "미완료"}
                        </div>
                      </td>
                    </tr>
                  );
                }
              )
            ) : (
              <tr>
                <td className="font-bold" colSpan={5}>
                  등록된 환자가 없습니다.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default PatientList;
