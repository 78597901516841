import { useEffect, useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import FrontPage from "../../components/FrontPage";
import SignUp from "../../components/SignUp";
import InputDefault from "../../components/InputDefault";
import useValidatedState from "../../hooks/useValidatedState";

import ButtonDefault from "../../components/ButtonDefault";
import axios from "axios";

function Account() {
  const navigate = useNavigate();
  const location = useLocation();
  // const [startDate, setStartDate] = useState(getDateBeforeNDays(14));
  //   const [endDate, setEndDate] = useState(getDateBeforeNDays(0));

  const [id, setId, idValidationMsg] = useValidatedState({
    required: true,
    fieldName: "email",
    type: "email",
  });
  const [password, setPassword, passwordValidationMsg] = useValidatedState({
    required: true,
    fieldName: "password",
    type: "password",
  });

  const [doctorId, setDoctorId] = useState("");

  // const [startDate, setStartDate, startDateValidationMsg] = useValidatedState({
  //   required: false,
  //   fieldName: "start date",
  //   type: "date",
  //   defaultVal: getDateArray(getDateBeforeNDays(14)),
  // });
  // const [endDate, setEndDate, endDateValidationMsg] = useValidatedState({
  //   required: false,
  //   fieldName: "end date",
  //   type: "date",
  //   defaultVal: getDateArray(getDateBeforeNDays(0)),
  // });

  const [reset, setReset] = useState(false);
  const [complete, setComplete] = useState(false);
  const [registered, setRegistered] = useState(false);
  const [changePswd, setChangePswd] = useState("");

  const isAllInputValid = () => {
    let allFields = reset
      ? [passwordValidationMsg]
      : location.pathname === "/signup"
      ? [
          signUpRules.full_name ? undefined : "",
          signUpRules.email ? undefined : "",
          signUpRules.hospital_name ? undefined : "",
          signUpRules.license_number ? undefined : "",
        ]
      : [idValidationMsg, passwordValidationMsg];
    // console.log(allFields);
    for (let i of allFields) {
      if (i !== undefined) {
        return false;
      }
    }

    return true;
  };
  // const loginData = { username: id, password };
  const [loginFormData, setLoginFormData] = useState({
    email: id,
    password,
  });
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (e.target.value === "SAVE") {
      const accessToken = localStorage.getItem("access_token");
      //비밀번호 push하기
      try {
        await axios
          .post(
            `https://apnotrack-api.asleep.ai/api/v2/password-reset`,
            {
              id: doctorId,
              new_password: password,
              confirm_password: changePswd,
            },
            {
              headers: { Authorization: `Bearer ${accessToken}` },
            }
          )
          .then(setComplete(true));
      } catch (error) {
        console.log(error);
      }
    } else if (e.target.value === "LOG IN") {
      // PUT Method로 정보 수정하기.
      setComplete(false);
      setReset(false);
      setRegistered(false);
      setId("");
      setPassword("");
      setChangePswd("");
    } else if (e.target.value === "REGISTER") {
      try {
        const response = await axios.post(
          `https://apnotrack-api.asleep.ai/api/v2/signup`,

          inputValues
        );
        console.log("Data updated successfully:", response.data);
        setRegistered(true);
        setInputValues({
          full_name: "",
          email: "",
          hospital_name: "",
          license_number: "",
        });
      } catch (error) {
        console.error("Error updating data:", error);
      }
    } else if (e.target.value === "CONTINUE") {
      try {
        axios
          .post(
            `${process.env.REACT_APP_APNOTRACK_URL}/v1/hub/auth/login`,
            loginFormData
          )
          .then((data) => {
            const response = data.data.result;
            if (response.access_token) {
              localStorage.setItem("access_token", response.access_token);
              sessionStorage.setItem("refresh_token", response.refresh_token);
            }
            if (response.role === "Admin") {
              navigate("/admin/doctor-list");
            } else if (
              response.role === "Doctor" &&
              response.last_login === null
            ) {
              setPassword("");
              setDoctorId(response.id);
              setReset(true);
            } else {
              navigate("/patient-list");
            }
            // localStorage.setItem("access_token", data.data.access_token),
            //   console.log(data);
          });
        // .then((data) => {
        // if (data.data.role === "Admin") {
        //   navigate("/admin/doctor-list");
        // } else if (
        //   data.data.role === "Doctor" &&
        //   data.data.last_login === null
        // ) {
        //   setReset(true);
        // } else {
        //   navigate("/patient-list");
        // }
        // console.log(data);
        // });
      } catch (error) {
        console.error("Error updating data:", error);
      }
    } else if (location.pathname === "/signup") {
      setComplete(false);
      setReset(false);
      setRegistered(false);
    }
  };

  // useEffect(() => {
  //   let urlParams = parseURL(location.search);
  //   console.log("Parsed url", urlParams);
  //   if (urlParams) {
  //     setStartDate(getDateArray(urlParams?.start_date));
  //     setEndDate(getDateArray(urlParams?.end_date));
  //     setId(urlParams?.uuid);
  //   }
  // }, []);

  const handleId = (e) => {
    setId(e.target.value);
    setLoginFormData({ ...loginFormData, email: e.target.value });
  };

  const handlePswd = (e) => {
    setPassword(e.target.value);
    setLoginFormData({ ...loginFormData, password: e.target.value });
  };

  const handleChangePswd = (e) => {
    setChangePswd(e.target.value);
  };

  const [inputValues, setInputValues] = useState({
    full_name: "",
    email: "",
    hospital_name: "",
    license_number: "",
  });

  const mailformat =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const { full_name, email, hospital_name, license_number } = inputValues;

  const signUpRules = {
    full_name: full_name.length >= 2,
    email: email.match(mailformat),
    hospital_name: hospital_name.length > 2,
    license_number: license_number.length > 2,
  };

  const handleInput = (e) => {
    const { id, value } = e.target;

    setInputValues({ ...inputValues, [id]: value });
  };

  useEffect(() => {
    setComplete(false);
    setReset(false);
    setRegistered(false);
    setPassword("");
    setChangePswd("");
  }, [location.pathname]);

  return (
    <FrontPage
      title={
        reset
          ? "PASSWORD RESET"
          : location.pathname === "/signup"
          ? registered
            ? ""
            : "Sign UP"
          : "Login"
      }
      note={
        reset
          ? "If you log in with a temporary password, you are required to change your password to protect your personal information."
          : location.pathname === "/signup"
          ? registered
            ? ""
            : "Please create an account"
          : "Log in to your account."
      }
      btnDisabled={!isAllInputValid()}
      handleClick={handleSubmit}
      reset={reset}
      complete={complete}
      registered={registered}
      correct={password === changePswd}
      pathName={location.pathname}
    >
      <div className="flex flex-col gap-4 py-6 w-full">
        {reset && !complete && (
          <>
            <InputDefault
              id={password}
              value={password || ""}
              type="password"
              placeholder="특수문자/대,소문자/숫자 포함 8자리 이상"
              handleChange={handlePswd}
              success={isAllInputValid()}
            />
            <InputDefault
              id={password}
              value={changePswd || ""}
              placeholder="Confirm New Password"
              handleChange={handleChangePswd}
              success={password === changePswd && changePswd !== ""}
            />
          </>
        )}
        {complete && (
          <div>
            <span className="text-center text-[#929292]">
              The Password Change
              <br /> Has Been Completed.
            </span>
          </div>
        )}
        {!reset && location.pathname !== "/signup" && (
          <>
            <InputDefault
              id={id}
              value={id || ""}
              placeholder="Email"
              handleChange={handleId}
              reset={reset}
              errorMsg={idValidationMsg}
              success={!idValidationMsg}
            />
            <InputDefault
              id={password || ""}
              value={password || ""}
              placeholder="password"
              handleChange={handlePswd}
              type="password"
              // reset={reset}
              errorMsg={passwordValidationMsg}
              success={!passwordValidationMsg}
            />
          </>
        )}
        {/* <div className='flex gap-3'>
          <InputDate placeholder='From...' handleChange={setStartDate} value={startDate} errorMsg={startDateValidationMsg} />
          <InputDate placeholder='To...' handleChange={setEndDate} value={endDate} errorMsg={endDateValidationMsg} />
        </div> */}
        {/* {!reset && location.pathname !== "/signup" && (
          <p
            className="text-end text-dark-blue font-medium cursor-pointer"
            onClick={resetPswd}
          >
            RESET PASSWORD
          </p>
        )} */}
        {location.pathname === "/signup" && !registered && (
          <>
            <SignUp
              handleInput={handleInput}
              name={inputValues[full_name]}
              email={inputValues[email]}
              hospital={inputValues[hospital_name]}
              LicenseNumber={inputValues[license_number]}
              signUpRules={signUpRules}
            />
          </>
        )}
        {registered && (
          <div className="flex flex-col justify-center items-center gap-12">
            <span className="text-center font-semibold uppercase text-gray-dark-2 text-2xl">
              Your registration is complete.
            </span>
            <span className="text-center text-[#586171]">
              If approved, you will receive <br />A Notification Via email.
              <br />
              Please check The approval Email and Log in.
            </span>
            <Link to="/">
              <ButtonDefault
                type="submit"
                value="LOG IN"
                size="small"
                width="medium"
                onlyBorder={true}
                handleClick={handleSubmit}
              />
            </Link>
          </div>
        )}
      </div>
    </FrontPage>
  );
}

export default Account;
