import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Icon from "../../components/Icon";
import ZoomBlueIcon from "../../assets/zoom-blue-icon.svg";
import ZoomGrayIcon from "../../assets/zoom-gray-icon.svg";

import { useEffect, useRef, useState } from "react";

import Loading from "../../components/Loading";
import { formatDateDot } from "../../lib/date";
import SleeAppLegend from "./slee_app_legend";
import ButtonDefault from "../../components/ButtonDefault";
import { useLocation, useNavigate } from "react-router-dom";

import CONSTANTS from "../../lib/constant";

import Summary from "./summary";
import DailyGraph from "./dailyGraph";
// import useGetPatientData from "../../hooks/useGetPatientData";
import { getStagesAndHours } from "./lib";
import IssueCodeModal from "../../components/modals/IssueCodeModal";
import EditInfoModal from "../../components/modals/EditInfoModal";
import useGetUserSleepData from "../../hooks/useGetPatientSleepData";

function SleepRoutineData({ value, name, handleClose, disabled, ...props }) {
  const accessToken = localStorage.getItem("access_token");

  const navigate = useNavigate();
  const location = useLocation();
  const [prescriptions, setPrescriptions] = useState([]);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [info, setInfo] = useState(false);

  const [showModal, setShowModal] = useState(false);

  // const [
  //   data,
  //   summary,
  //   stackedData,
  //   resG,
  //   errorG,
  //   msgG,
  //   isLoadedG,
  //   isLoadingG,
  //   getData,
  //   code,
  // ] = useGetPatientData();

  const astId = location.state?.ast_id;
  // console.log(astId);
  // const astId = "G-20240806015234-pKhvIvKgKPKTHUHdkHsW";
  // const apiKey = process.env.REACT_APP_ASLEEP_API_KEY;

  // const [averageStat, setAverageStat] = useState([]);

  //백엔드 개발되면 startDate, endDate 수정하기
  const {
    averageStats,
    singleSessionList,
    ahiData,
    unstablePatternCount,
    error,
    isLoading,
    isLoaded,
    msg,
    isSession,
  } = useGetUserSleepData(astId, startDate, endDate);

  // const access_token = localStorage.getItem("access_token");

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_APNOTRACK_URL}/v1/hub/doctors/me/patients/${location.state.id}/prescriptions`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setPrescriptions(response.data.result.reverse());
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    setStartDate(prescriptions[0]?.start_date);
    setEndDate(prescriptions[0]?.end_date);
  }, [prescriptions]);

  const [dataModified, setDataModified] = useState([]);

  const [isZoomed, setIsZoomed] = useState(false);

  // const [endDate, setEndDate] = useState()

  const [detailData, setDetailData] = useState([]);
  const [expire, setExpire] = useState(false);
  const componentRef = useRef();

  const handleBack = () => {
    navigate("/patient-list");
  };

  const toggleZoomAll = () => {
    let dtMdf = [...dataModified];

    for (let i = 0; i < dataModified.length; i++) {
      // handleZoom(i)
      let row = dataModified[i];

      if (!row?.disabled) {
        let r = { ...row };

        r.zoom = isZoomed;

        r = getStagesAndHours(r);

        dtMdf[i] = r;
      }
    }

    setDataModified(dtMdf);
  };

  useEffect(() => {
    toggleZoomAll();
  }, []);

  const openModal = (e) => {
    e.preventDefault();
    const value = e.target.value;
    if (value === "환자 정보") {
      setInfo(true);
    }
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setExpire(false);
    setInfo(false);
  };

  const locationData = location?.state;

  const registered = locationData?.is_registered;

  useEffect(() => {
    setDetailData(locationData);
  }, [locationData]);

  const birthDay = new Date(detailData?.birth_date);
  const year = String(birthDay?.getFullYear());
  const month = String(birthDay?.getMonth() + 1).padStart(2, "0");
  const day = String(birthDay?.getDate()).padStart(2, "0");
  const dateOfBirth = `${year}-${month}-${day}`;
  let Gender;
  if (detailData.gender === "MALE") {
    Gender = "남";
  } else if (detailData.gender === "FEMALE") {
    Gender = "여";
  } else {
    Gender = "기타";
  }

  const handleCheckCode = async () => {
    setExpire(true);
    // setDetailData({ ...detailData, code: response.data.code });

    setShowModal(true);
  };

  return (
    <div className="h-[100vh] col-span-full  flex flex-col w-full gap-6 p-8 bg-cream">
      {showModal && expire && (
        <IssueCodeModal
          handleCloseModal={handleCloseModal}
          // existCode={existCode}
          id={detailData.id}
          // setExistCode={setExistCode}
          setDetailData={setDetailData}
          detailData={detailData}
        />
      )}

      {showModal && info && (
        <EditInfoModal
          handleCloseModal={handleCloseModal}
          birth_date={dateOfBirth}
          setDetailData={setDetailData}
          detailData={detailData}
        />
      )}

      <div className="flex justify-between items-center w-full">
        <button
          className="border border-gray-light absolute bottom-4 right-4 rounded-lg bg-white shadow-default p-2 z-10"
          onClick={() => setIsZoomed(!isZoomed)}
        >
          <img
            src={isZoomed ? ZoomGrayIcon : ZoomBlueIcon}
            className="w-10 h-10"
            alt="none"
          />
        </button>
        <div className="flex flex-col gap-8">
          <div className="flex gap-6 ">
            <button onClick={handleBack}>
              <Icon icon={faAngleLeft} size="2xl" />
            </button>
            <div className="text-[33px] cursor-pointer" onClick={handleBack}>
              뒤로
            </div>
          </div>
          {detailData.length === 0 ? (
            <div>Loading...</div>
          ) : (
            <div className="text-[40px] pl-8 ">
              {detailData?.name}({year}.{month}.{day} / {Gender} /{" "}
              {locationData.patient_code})
            </div>
          )}
        </div>

        <div className="flex flex-col-reverse gap-6 h-fit">
          <div className="flex justify-end gap-6">
            <SleeAppLegend title="Sleep stages" data={CONSTANTS.SLEEP_STAGES} />
            <SleeAppLegend
              title="Breath events"
              data={CONSTANTS.APNEA_STAGES}
            />
          </div>

          <div className="flex gap-6 h-full justify-end xl:justify-normal">
            <select
              className="w-[250px]"
              onChange={(e) => {
                e.preventDefault();
                const selectedOption = prescriptions.find(
                  (p) =>
                    `${formatDateDot(p.start_date)} - ${formatDateDot(
                      p.end_date
                    )}` === e.target.value
                );
                if (selectedOption) {
                  setStartDate(selectedOption.start_date);
                  setEndDate(selectedOption.end_date);
                }
              }}
            >
              {prescriptions?.map((list, ind) => {
                return (
                  <option key={list.id}>
                    {formatDateDot(list.start_date)} -{" "}
                    {formatDateDot(list.end_date)}
                  </option>
                );
              })}
            </select>
            <ButtonDefault
              value="처방 기간 설정"
              handleClick={handleCheckCode}
            />
            <ButtonDefault
              value="환자 정보"
              handleClick={openModal}
              hollow={true}
            />
          </div>
        </div>
      </div>
      <span
        className={`${
          registered && isSession
            ? "display-none"
            : "font-bold font-20 text-black text-xl fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]"
        }`}
      >
        환자코드 등록이 미완료 되었거나, 조회할 수면 데이터가 없습니다.
      </span>
      <div
        className={`h-[84vh] w-full bg-white pl-8 pr-2 py-10 scroll-m-2 shadow-default flex flex-col gap-3 ${
          registered && isSession
            ? "overflow-y-scroll"
            : " opacity-30  overflow-y-hidden"
        }`}
      >
        <Loading
          isLoading={isLoading}
          isLoaded={isLoaded}
          error={error}
          msg={msg}
          res={"resG"}
          output={
            <div
              ref={componentRef}
              id="slee-app-data"
              className={`flex flex-col gap-4 px-3 `}
            >
              <Summary
                startDate={startDate}
                endDate={endDate}
                averageStats={averageStats?.result}
                summary={averageStats?.result?.average_stats}
                unstablePatternCount={unstablePatternCount}
                ahiData={ahiData}
              />

              {singleSessionList.length > 0 && (
                <DailyGraph
                  singleSessionList={singleSessionList}
                  isZoomed={isZoomed}
                />
              )}
            </div>
          }
        />
      </div>
    </div>
  );
}

export default SleepRoutineData;
