// const BASE_URL = 'http://localhost:9000';
const BASE_URL = "https://apnotrack-api.asleep.ai/api/v2/report";

const CONSTANTS = {
  BASE_URL: BASE_URL,
  BASE_API_URL: `${BASE_URL}`,

  GENDER: {
    MALE: "male",
    FEMALE: "female",
  },

  LIMIT: 10,

  REQUEST_STATUS: {
    FAILURE: -1,
    SUCCESS: 1,
    PENDING: 0,
  },
  DAYS: [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ],

  BTN_TYPE: {
    ADD: "add",
    REMOVE: "remove",
  },
  MONTHS: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ].map((val, ind) => {
    return {
      name: val,
      value: ind + 1,
    };
  }),
  YEARS: [...Array(5)].map((val, ind) => {
    return {
      name: 2022 + ind,
      value: 2022 + ind,
    };
  }),
  SLEEP_STAGES: [
    {
      color: "#FFB84C",
      tip: "Wake",
      height: "100%",
    },
    {
      color: "#95BDFF",
      tip: "Light sleep",
      height: "50%",
    },
    {
      color: "#2F58CD",
      tip: "Deep sleep",
      height: "25%",
    },
    {
      color: "#EB455F",
      tip: "REM",
      height: "75%",
    },
    // {
    //     color: '#808080',
    //     tip: 'NA',
    //     height: '25%'
    // }
  ],
  STACKED_COLORS: [
    {
      color: "#FFB84C",
      tip: "Wake",
      variable: "wake",
      code: 0,
    },
    {
      color: "#EB455F",
      tip: "REM",
      variable: "rem",
      code: 3,
    },
    {
      color: "#95BDFF",
      tip: "Light",
      variable: "lightSleep",
      code: 1,
    },
    {
      color: "#2F58CD",
      tip: "Deep",
      variable: "deepSleep",
      code: 2,
    },
    {
      color: "#808080",
      tip: "NA",
      variable: "na",
      code: 4,
    },
  ],
  APNEA_STAGES: [
    {
      color: "#03C988",
      tip: "Stable",
    },
    {
      color: "#A7727D",
      tip: "UnStable",
    },
  ],
  ASLEE_APP_TABLE_TITLES: [
    {
      title: "sleep latency",
      variable: "sleep_latency",
    },
    {
      title: "time in sleep",
      variable: "time_in_sleep",
    },

    {
      title: "time in light",
      variable: "time_in_light",
    },
    {
      title: "time in deep",
      variable: "time_in_deep",
    },
    {
      title: "time in rem",
      variable: "time_in_rem",
    },
    {
      title: "number of wake",
      variable: "waso_count",
    },
    {
      title: "sleep efficiency",
      variable: "sleep_efficiency",
    },
  ],
  DAY_COLOR: {
    sunday: "red",
    saturday: "sat-blue",
  },
  DATA: [
    {
      x: 1,
      lightSleep: 38,
      wake: 19,
      deepSleep: 9,
      rem: 4,
      na: 5,
    },
    {
      x: 2,
      lightSleep: 16,
      wake: 14,
      deepSleep: 96,
      rem: 40,
      na: 1,
    },
    {
      x: 3,
      lightSleep: 64,
      wake: 96,
      deepSleep: 64,
      rem: 40,
      na: 8,
    },
    {
      x: 4,
      lightSleep: 32,
      wake: 48,
      deepSleep: 64,
      rem: 40,
      na: 5,
    },
    {
      x: 5,
      lightSleep: 12,
      wake: 18,
      deepSleep: 14,
      rem: 10,
      na: 2,
    },
    {
      x: 6,
      lightSleep: 54,
      wake: 23,
      deepSleep: 19,
      rem: 14,
      na: 9,
    },
  ],
};

export default CONSTANTS;
