import React, { useState } from "react";
import ECharts from "echarts-for-react";

const AHIChart = ({ ahiData }) => {
  // const days = getDatesBetween(detailData.start_date, detailData.end_date);

  const [options, setOptions] = useState({
    tooltip: { trigger: "item", formatter: "{b} <br/>AHI : {c}" },
    width: "75%",
    xAxis: {
      type: "category",
      data: ahiData?.map((a) => a.day),
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        data: ahiData?.map((a) => a.ahi),
        type: "bar",
        barWidth: "20%",
      },
    ],
  });

  return (
    <div className="flex flex-col gap-5 w-[215mm] h-[500px]">
      <h2 className="text-2xl uppercase font-medium text-black mt-[1.5rem] px-3">
        AHI GRAPH
      </h2>
      <ECharts option={options} opts={{ renderer: "svg", height: "450" }} />
    </div>
  );
};

export default AHIChart;
