import axios from "axios";
import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Apnotrack from "../../../assets/apnotrackhub.svg";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import Icon from "../../../components/Icon";

import EditDoctorInfoModal from "../../../components/modals/EditDoctorInfoModal";

function DoctorList() {
  const accessToken = localStorage.getItem("access_token");

  const [list, setList] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredList, setFilteredList] = useState([]);
  const [sortValue, setSortValue] = useState("basic");
  const [showModal, setShowModal] = useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState({
    full_name: "",
    hospital_name: "",
    license_number: "",
    email: "",
  });
  // console.log(typeof selectedDoctor.license_number);

  const navigate = useNavigate();

  const fetchData = useCallback(() => {
    axios(`https://apnotrack-api.asleep.ai/api/v2/doctor/list`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }).then((data) => {
      setList(data.data);
      setFilteredList(data.data);
    });
    setShowModal(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    const filtered = list.filter((item) =>
      item.full_name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredList(sortList(filtered, sortValue));
  };

  const sortList = (listToSort, sortValue) => {
    // console.log(sortValue);
    if (sortValue === "basic") {
      return list.filter((item) => listToSort.includes(item)); // 기본 리스트 순서 유지
    }

    return [...listToSort].sort((a, b) => {
      if (sortValue === "full_name") {
        return a.full_name.localeCompare(b.full_name);
      } else if (sortValue === "Verify") {
        const stateOrder = { Pending: 1, Verified: 2 };
        return stateOrder[a.account_status] - stateOrder[b.account_status];
      }
      return 0; // 기본 순서 유지
    });
  };

  // MEMO : 정렬기능 필요할 때 주석해제
  const Sorting = (e) => {
    const sortValue = e.target.value;
    setSortValue(sortValue);
    setFilteredList(sortList(filteredList, sortValue));
  };

  const openModal = (doctor) => {
    setSelectedDoctor(doctor);
    // console.log(doctor);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const verify = async (doctor) => {
    // console.log(accessToken);
    if (doctor.account_status === "Pending") {
      try {
        await axios.put(
          `https://apnotrack-api.asleep.ai/api/v2/doctor/verify/${doctor.id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        fetchData();
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("access_token");
    if (!token) {
      navigate("/");
    }
  }, []);

  return (
    <div className="h-full col-span-full  flex flex-col w-full gap-6 p-8 bg-cream ">
      {showModal && (
        <EditDoctorInfoModal
          handleCloseModal={handleCloseModal}
          filteredList={filteredList}
          setFilteredList={setFilteredList}
          full_name={selectedDoctor.full_name}
          hospital_name={selectedDoctor.hospital_name}
          license_number={selectedDoctor.license_number}
          email={selectedDoctor.email}
          id={selectedDoctor.id}
          fetchData={fetchData}
        />
      )}
      <section className="flex justify-between items-end">
        <img src={Apnotrack} className="w-[150px]" alt="apnotrack" />
        <div className="relative">
          <input
            className="w-[250px] h-[40px] p-[12px] shadow-default "
            placeholder="검색"
            value={search}
            onChange={handleSearch}
          />
          <div className="absolute top-[50%] right-3 mt-[-10px] cursor-pointer ">
            <Icon icon={faMagnifyingGlass} size="xl" />
          </div>
        </div>
      </section>
      <div className=" h-[84vh] overflow-y-scroll w-full bg-white pl-8 pr-8 pb-10 scroll-m-2 shadow-default flex flex-col gap-3">
        <h1 className="font-bold text-4xl mb-[30px] pt-10 text-black bg-white">
          Doctor List
        </h1>

        <div className="flex justify-between items-end">
          <label for="sort">
            정렬 :
            <select name="sort" id="sort" onChange={Sorting}>
              <option value="basic">기본순</option>
              <option value="full_name">이름순</option>
              <option value="Verify">Verify</option>
            </select>
          </label>
          {/* <ButtonDefault
            value="Edit Doctor Info"
            size="small"
            handleClick={openModal}
          /> */}
        </div>
        <table className="text-center">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email(ID)</th>
              <th>Hospital Name</th>
              <th>Physician's License Number</th>
              <th>Patients Count</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {filteredList.map((doctor) => {
              const {
                id,
                full_name,
                email,
                hospital_name,
                license_number,
                patient_count,
                account_status,
              } = doctor;

              return (
                <tr key={id}>
                  <td
                    onClick={() => openModal(doctor)}
                    className="cursor-pointer"
                  >
                    {full_name}
                  </td>
                  <td
                    onClick={() => openModal(doctor)}
                    className="cursor-pointer"
                  >
                    {email}
                  </td>
                  <td
                    onClick={() => openModal(doctor)}
                    className="cursor-pointer"
                  >
                    {hospital_name}
                  </td>
                  <td
                    onClick={() => openModal(doctor)}
                    className="cursor-pointer"
                  >
                    {license_number}
                  </td>
                  <td>{patient_count}</td>
                  <td className="items-center ">
                    <button
                      onClick={() => verify(doctor)}
                      className={`${
                        account_status === "Verified"
                          ? "text-[#2CBC77] bg-[#F1FFF0] cursor-default"
                          : "bg-[#1A2663] rounded-[4px] w-[79px] m-auto text-white"
                      } rounded-[4px] w-[79px] m-auto `}
                    >
                      {account_status === "Verified" ? "Verified" : "Verify"}
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default DoctorList;
