import { useEffect, useState } from "react";
import CalendarLightIcon from "../../../assets/calendar-icon-dark.svg";
import ButtonDefault from "../../ButtonDefault";
import BaseModal from "../BaseModal";
import ModalTitle from "../ModalTitle";

import { Calendar } from "react-date-range";
import { ko } from "date-fns/locale";
import { formatDateDash } from "../../../lib/date";
import { format } from "date-fns";
import axios from "axios";

function EditInfoModal({
  handleCloseModal,
  birth_date,
  setDetailData,
  detailData,
}) {
  const { name, gender, tonsil_size, id } = detailData;

  const [formData, setFormData] = useState({
    name,
    birth_date: birth_date,
    gender,
    tonsil_size,
    stop_bang_responses: detailData.stop_bang_responses,
  });
  const [showCalendar, setShowCalendar] = useState(false);

  const [isNotEmpty, setIsNotEmpty] = useState({
    name: undefined,
    birth_date: undefined,
    gender: undefined,
    tonsil_size: undefined,
  });

  const [stopBangResponse, setStopBangResponse] = useState([]);

  const handleChange = (e) => {
    e.preventDefault();

    const { name, value } = e.target;

    setIsNotEmpty({
      ...isNotEmpty,
      [name]: value ? true : false,
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const { name, birth_date, gender, tonsil_size } = formData;
    const isNameValid = name?.trim() !== "";
    const isBirthValid = birth_date !== "";
    const isGenderValid = gender?.trim() !== "";
    const isTonsilSizeValid = tonsil_size !== "";

    return isNameValid && isBirthValid && isGenderValid && isTonsilSizeValid;
  };

  const handleSubmit = async (e) => {
    // const { name, value } = e.target;

    e.preventDefault();

    const accessToken = localStorage.getItem("access_token");

    try {
      const response = await axios
        .patch(
          `${process.env.REACT_APP_APNOTRACK_URL}/v1/hub/doctors/me/patients/${id}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then(handleCloseModal);
      setDetailData({ ...detailData, ...formData });

      // 업데이트된 데이터를 다시 요청하여 설정

      console.log("Data updated successfully:", response);
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  // console.log(detailData);

  useEffect(() => {
    fetch("data/survey.json")
      .then((res) => res.json())
      .then((data) => setStopBangResponse(data));
  }, []);

  const renderTextWithNewLines = (text) => {
    return text.split("\n").map((str, index) => (
      <span key={index}>
        {str}
        <br />
      </span>
    ));
  };

  const handleSurveyClick = (index, answer) => {
    setStopBangResponse((prevData) =>
      prevData.map((item, idx) =>
        idx === index ? { ...item, check: answer } : item
      )
    );

    // stop_bang_responses 업데이트
    setFormData((prevFormData) => {
      const updatedResponses = [...prevFormData.stop_bang_responses];
      updatedResponses[index] = answer; // 해당 인덱스에 값을 넣음
      return {
        ...prevFormData,
        stop_bang_responses: updatedResponses,
      };
    });
  };

  const handleSelectDate = (date) => {
    const formattedDate = formatDateDash(date);
    setFormData({
      ...formData,
      birth_date: formattedDate,
    });
    setShowCalendar(false);
  };

  return (
    <BaseModal handleCloseModal={handleCloseModal}>
      <div className="flex justify-between items-baseline gap-4 px-20 pt-8 pb-6">
        <ModalTitle title="환자 정보" note="" />
        <span
          className="flex justify-center items-center font-bold text-[#1C265F] cursor-pointer"
          onClick={handleCloseModal}
        >
          CLOSE
        </span>
      </div>
      <section className="flex flex-col gap-8 px-20 items-center">
        <div className="w-full">
          <div className="mb-4 flex gap-4 items-center">
            <div className="w-[40%]">이름</div>
            <input
              type="text"
              name="name"
              placeholder="Full Name"
              autoComplete="off"
              value={formData.name}
              onChange={handleChange}
              className="w-full px-4 py-3 border-2 border-gray-light"
            />
          </div>

          <div className="mb-4 flex gap-4 items-center">
            <div className="w-[40%]">생년월일</div>
            <div
              className="flex items-center border-2 border-gray-light px-4 py-3 w-full cursor-pointer "
              onClick={() => setShowCalendar(!showCalendar)}
            >
              <input
                type="text"
                value={
                  formData.birth_date
                    ? format(formData.birth_date, "yy.MM.dd")
                    : ""
                }
                className={`w-full cursor-pointer`}
                placeholder="생년월일"
                readOnly
              />
              <img src={CalendarLightIcon} alt="none" className="w-[5%]" />
            </div>
          </div>
          {showCalendar && (
            <div>
              <div
                className="fixed inset-0 bg-gray-500 bg-opacity-50 z-10"
                onClick={() => {
                  setShowCalendar(false);
                }}
              ></div>

              <div className="absolute top-1/3 left-1/2 transform -translate-x-1/2 mt-2 z-40 w-[40%]">
                <div className="custom-date-range">
                  <Calendar
                    direction="horizontal"
                    onChange={handleSelectDate}
                    date={
                      formData.birth_date
                        ? new Date(formData.birth_date)
                        : new Date()
                    }
                    locale={ko}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="mb-4 flex gap-4 items-center">
            <div className="w-[40%]">성별</div>
            <div className="w-full px-4 py-3 border-2 border-gray-light">
              <label htmlFor="gender">
                <select
                  onChange={handleChange}
                  className="w-full"
                  name="gender"
                  id="gender"
                  defaultValue={gender || "default"}
                >
                  <option value="gender" disabled hidden>
                    {gender === "MALE" ? "남" : gender === "FEMALE" ? "여" : ""}
                  </option>
                  <option value="MALE">남</option>
                  <option value="FEMALE">여</option>
                </select>
              </label>
            </div>
          </div>
          <div className="mb-4 flex gap-4 items-center">
            <div className="w-[40%]">편도 비대 검사</div>
            <div className=" w-full px-4 py-3 border-2 border-gray-light">
              <label>
                <select
                  onChange={handleChange}
                  defaultValue={formData.tonsil_size || "default"}
                  className="w-full"
                  name="tonsil_size"
                >
                  <option className="text-gray-light" value="default" disabled>
                    편도 크기 검사
                  </option>
                  <option value={1}>Grade 1</option>
                  <option value={2}>Grade 2</option>
                  <option value={3}>Grade 3</option>
                  <option value={4}>Grade 4</option>
                </select>
              </label>
            </div>
          </div>

          {
            <div>
              <p className="mb-8 text-[#4c5463] text-[22px] font-extrabold font-['Pretendard'] uppercase">
                개정 버전 STOP-BANG SURVEY
              </p>
              <section className=" h-[300px] pr-[12px] overflow-scroll">
                {stopBangResponse?.map(({ list, guideText, check }, index) => (
                  <div key={index} className="flex flex-col gap-4 mb-12">
                    <div className="flex justify-between">
                      <div className="text-[#1c265f] text-[20px] font-bold">
                        {index + 1}. {renderTextWithNewLines(list)}
                      </div>
                      <div className="flex gap-4">
                        <button
                          className={`w-[80px] h-[40px] px-4 py-2 rounded-[33px] ${
                            formData.stop_bang_responses[index] === true
                              ? "bg-[#1c265f] text-white"
                              : "bg-[#eaeaea] text-[#1c265f]"
                          }`}
                          onClick={() => handleSurveyClick(index, true)}
                        >
                          예
                        </button>
                        <button
                          className={`w-[80px] h-[40px] px-4 py-2 rounded-[33px] ${
                            formData.stop_bang_responses[index] === false
                              ? "bg-[#1c265f] text-white"
                              : "bg-[#eaeaea] text-[#1c265f]"
                          }`}
                          onClick={() => handleSurveyClick(index, false)}
                        >
                          아니오
                        </button>
                      </div>
                    </div>
                    <div>{renderTextWithNewLines(guideText)}</div>
                  </div>
                ))}
              </section>
            </div>
          }
          <section className="flex justify-center gap-4 px-20 pt-8 pb-6 text-center">
            <ButtonDefault
              type="submit"
              value="SAVE"
              disabled={!validateForm()}
              handleClick={handleSubmit}
            />
          </section>
        </div>
      </section>
    </BaseModal>
  );
}

export default EditInfoModal;
